export function courseMainTableHead () {
    return [
        {
            text    : 'ID',
            value   : 'id'
        },
        {
            text    : 'Name URK',
            value   : 'name'
        },
        {
            text    : 'Name ENG',
            value   : 'nameEn'
        },
        {
            text    : 'Picture',
            value   : 'picture'
        },
        {
            text    : 'Created time',
            value   : 'createdAt'
        },
        {
            text    : 'Type',
            value   : 'type'
        },
        {
            text    : 'Status',
            value   : 'status',
            sort    : (a, b) => {
                const nameA = a.data.title.toLowerCase();
                const nameB = b.data.title.toLowerCase();
                
                if (nameA > nameB) {
                    return -1
                }
                
                if (nameA < nameB) {
                    return 1
                }
                
                return 0
            }
        },
        {
            text    : 'Actions',
            value   : 'actions',
            sortable: false
        }
    ]
}
